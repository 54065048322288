import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Home } from './pages/home';
import { Layout } from './layout';
import { ROUTES } from './utils/router';
import './utils/i18n';
const getValidLanguage = (lang) => {
    const validLanguages = ['ru', 'en'];
    return validLanguages.includes(lang) ? lang : 'en';
};
export const App = () => {
    const savedLanguage = localStorage.getItem('i18nextLng');
    const [selectedLanguage, setSelectedLanguage] = useState(savedLanguage ? getValidLanguage(savedLanguage) : 'en');
    const { i18n } = useTranslation();
    useEffect(() => {
        if (!savedLanguage) {
            const lowerCaseTgLanguage = savedLanguage?.toLowerCase();
            const language = lowerCaseTgLanguage && getValidLanguage(lowerCaseTgLanguage);
            setSelectedLanguage(language || 'en');
        }
    }, [savedLanguage]);
    useEffect(() => {
        i18n.changeLanguage(selectedLanguage);
        localStorage.setItem('i18nextLng', selectedLanguage);
    }, [selectedLanguage, i18n]);
    return (_jsx(Layout, { setSelectedLanguage: setSelectedLanguage, selectedLanguage: selectedLanguage, children: _jsxs(Routes, { children: [_jsx(Route, { path: ROUTES.HOME, element: _jsx(Home, {}) }), _jsx(Route, { path: "*", element: _jsx(Navigate, { to: "/", replace: true }) })] }) }));
};
