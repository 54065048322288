// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pbJrM5fv1IylS_DmBM6A {
  max-width: 1160px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/container/container.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,WAAA;EACA,cAAA;EACA,eAAA;AACF","sourcesContent":[".container {\n  max-width: 1160px;\n  width: 100%;\n  margin: 0 auto;\n  padding: 0 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `pbJrM5fv1IylS_DmBM6A`
};
export default ___CSS_LOADER_EXPORT___;
