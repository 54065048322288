// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pFzJH8V12vTF3RcTEcb1 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
  padding: 12px;
  border-radius: 176px;
  transition: all 0.3s ease 0s;
  cursor: pointer;
}

/*--------------------disabled--------------------*/
.SufI22JRFRndyEsrnNyp {
  opacity: 0.75;
}

.SufI22JRFRndyEsrnNyp:hover {
  cursor: not-allowed;
  opacity: 0.85;
}

/*--------------------/disabled--------------------*/
.FesNQjgskHNfXbbJR92A {
  background: transparent;
}

.Zyk6MXYyEPIY2SVsDDUf {
  padding: 12px 24px;
  color: rgb(var(--background-white));
  background: rgb(var(--color-main-1));
}
.Zyk6MXYyEPIY2SVsDDUf:hover {
  background: rgba(var(--color-main-1), 0.9);
}

.KQmzwfS1JtTpeAjeKKP7 {
  padding: 12px 24px;
  border: 1px solid rgba(221, 227, 230, 0.3);
}
.KQmzwfS1JtTpeAjeKKP7:hover {
  border: 1px solid rgba(221, 227, 230, 0.5);
  background: rgba(138, 151, 170, 0.1);
}`, "",{"version":3,"sources":["webpack://./src/components/ui/button/button.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,QAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,aAAA;EACA,oBAAA;EACA,4BAAA;EACA,eAAA;AACF;;AAEA,mDAAA;AAEA;EACE,aAAA;AAAF;;AAGA;EACE,mBAAA;EACA,aAAA;AAAF;;AAGA,oDAAA;AAEA;EACE,uBAAA;AADF;;AAIA;EACE,kBAAA;EACA,mCAAA;EACA,oCAAA;AADF;AAEE;EACE,0CAAA;AAAJ;;AAIA;EACE,kBAAA;EACA,0CAAA;AADF;AAEE;EACE,0CAAA;EACA,oCAAA;AAAJ","sourcesContent":[".btn {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 8px;\n  font-size: 16px;\n  font-weight: 600;\n  line-height: 150%;\n  padding: 12px;\n  border-radius: 176px;\n  transition: all 0.3s ease 0s;\n  cursor: pointer;\n}\n\n/*--------------------disabled--------------------*/\n\n.disabled {\n  opacity: 0.75;\n}\n\n.disabled:hover {\n  cursor: not-allowed;\n  opacity: 0.85;\n}\n\n/*--------------------/disabled--------------------*/\n\n.primaryVariant {\n  background: transparent;\n}\n\n.actionVariant {\n  padding: 12px 24px;\n  color: rgb(var(--background-white));\n  background: rgb(var(--color-main-1));\n  &:hover {\n    background: rgba(var(--color-main-1), 0.90);\n  }\n}\n\n.borderVariant {\n  padding: 12px 24px;\n  border: 1px solid rgba(221, 227, 230, 0.30);\n  &:hover {\n    border: 1px solid rgba(221, 227, 230, 0.50);\n    background: rgba(138, 151, 170, 0.1);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": `pFzJH8V12vTF3RcTEcb1`,
	"disabled": `SufI22JRFRndyEsrnNyp`,
	"primaryVariant": `FesNQjgskHNfXbbJR92A`,
	"actionVariant": `Zyk6MXYyEPIY2SVsDDUf`,
	"borderVariant": `KQmzwfS1JtTpeAjeKKP7`
};
export default ___CSS_LOADER_EXPORT___;
