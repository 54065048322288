// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Inter:regular,500,600,700,800,900);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  background-color: rgb(var(--background-secondary));
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

button {
  background-color: transparent;
  border: none;
}

a {
  color: inherit;
  text-decoration: none;
}

a:visited {
  color: inherit;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: inherit;
  line-height: 1;
}

ul,
li {
  list-style: none;
}

main,
section,
header,
footer {
  width: 100%;
}

body {
  font-family: "Inter";
  font-size: 16px;
}

.wrapper {
  min-height: 100vh;
  margin: 0 auto;
  padding: 24px 0 0;
  position: relative;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: space-between;
}

main {
  flex: 1;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 415px) {
  .swiper-slide {
    max-width: content !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/index.scss"],"names":[],"mappings":"AAEA;EACI,sBAAA;EACA,UAAA;EACA,SAAA;AAAJ;;AAGA;EACI,kDAAA;AAAJ;;AAGA;;EAEI,gBAAA;EACA,kBAAA;AAAJ;;AAGA;EACI,6BAAA;EACA,YAAA;AAAJ;;AAGA;EACI,cAAA;EACA,qBAAA;AAAJ;;AAEA;EACI,cAAA;EACA,qBAAA;AACJ;;AACA;;;;;;EAMI,cAAA;EACA,cAAA;AAEJ;;AAAA;;EAEI,gBAAA;AAGJ;;AADA;;;;EAII,WAAA;AAIJ;;AADA;EACI,oBAAA;EACA,eAAA;AAIJ;;AADA;EACI,iBAAA;EACA,cAAA;EACA,iBAAA;EACA,kBAAA;EACA,aAAA;EACA,gBAAA;EACA,sBAAA;EACA,8BAAA;AAIJ;;AADA;EACI,OAAA;AAIJ;;AADA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;AAIJ;;AADA;EACI;IACI,6BAAA;EAIN;AACF","sourcesContent":["@import url(https://fonts.googleapis.com/css?family=Inter:regular,500,600,700,800,900);\n\n* {\n    box-sizing: border-box;\n    padding: 0;\n    margin: 0;\n}\n\nhtml {\n    background-color: rgb(var(--background-secondary));\n}\n\nhtml,\nbody {\n    max-width: 100vw;\n    overflow-x: hidden;\n}\n\nbutton {\n    background-color: transparent;\n    border: none;\n}\n\na {\n    color: inherit;\n    text-decoration: none;\n}\na:visited {\n    color: inherit;\n    text-decoration: none;\n}\nh1,\nh2,\nh3,\nh4,\nh5,\nh6 {\n    color: inherit;\n    line-height: 1;\n}\nul,\nli {\n    list-style: none;\n}\nmain,\nsection,\nheader,\nfooter {\n    width: 100%;\n}\n\nbody {\n    font-family: 'Inter';\n    font-size: 16px;\n}\n\n.wrapper {\n    min-height: 100vh;\n    margin: 0 auto;\n    padding: 24px 0 0;\n    position: relative;\n    display: flex;\n    overflow: hidden;\n    flex-direction: column;\n    justify-content: space-between;\n}\n\nmain {\n    flex: 1;\n}\n\n.swiper-slide {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n@media (max-width: 415px) {\n    .swiper-slide {\n        max-width: content !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
