import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-shadow */
import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { v1 } from 'uuid';
import { Container } from '../../components/container';
import { Button } from '../../components/ui/button';
import { servicesRef } from '../../pages/home/services';
import { advantagesRef } from '../../pages/home/advantages';
import { providingRef } from '../../pages/home/providing';
import { contactsRef } from '../contacts';
import { footerContacts } from '../../mocks/footer';
import { SvgSelector } from '../../assets/svg-selector';
import s from './footer.module.scss';
export const Footer = ({ selectedLanguage, setSelectedLanguage }) => {
    const { t } = useTranslation();
    const { pathname, hash } = useLocation();
    const scroll = (ref) => {
        if (ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
    const handleLinkClick = (ref) => {
        scroll(ref);
    };
    const handleСontactsClick = (event, ref) => {
        event.preventDefault();
        scroll(ref);
    };
    useEffect(() => {
        window.scrollTo(0, 0);
        if (hash) {
            const targetElement = document.querySelector(hash);
            if (targetElement) {
                targetElement.scrollIntoView({ behavior: 'smooth' });
            }
        }
        window.history.replaceState(null, '', pathname);
    }, [pathname, hash]);
    return (_jsx("footer", { className: s.footer, children: _jsxs(Container, { children: [_jsxs("div", { className: s.footerTop, children: [_jsx("div", { className: s.footerTopLeft, children: _jsx("div", { className: s.footerTopList, children: _jsxs("div", { className: s.footerTopUl, children: [_jsx("span", { className: s.footerTopLi, children: _jsx(Link, { to: "/#providing", "aria-label": "providing link", onClick: () => handleLinkClick(providingRef), children: t('footer.discover.about') }) }), _jsx("span", { className: s.footerTopLi, children: _jsx(Link, { to: "/#advantages", "aria-label": "advantages link", onClick: () => handleLinkClick(advantagesRef), children: t('footer.discover.team') }) }), _jsx("span", { className: s.footerTopLi, children: _jsx(Link, { to: "/#services", "aria-label": "services link", onClick: () => handleLinkClick(servicesRef), children: t('footer.discover.services') }) }), _jsx("span", { className: s.footerTopLi, children: _jsx(Link, { to: "/#", "aria-label": "contacts link", onClick: event => handleСontactsClick(event, contactsRef), children: t('footer.discover.contacts') }) }), _jsx("span", { className: s.footerTopLi, children: footerContacts.map(el => (_jsx("span", { className: s.footerTopLi, onClick: () => window.open(el.link, '_blank'), children: el.title }, v1()))) })] }) }) }), _jsx("div", { className: s.footerTopRight, children: _jsxs(Button, { variant: "primary", className: s.headerActionsLang, onClick: () => {
                                    setSelectedLanguage(selectedLanguage => (selectedLanguage === 'ru' ? 'en' : 'ru'));
                                }, children: [_jsx(SvgSelector, { id: "lang" }), " ", selectedLanguage === 'ru' ? 'Рус' : 'Eng'] }) })] }), _jsx("div", { className: s.footerDivider }), _jsxs("div", { className: s.footerBottom, children: [_jsx(Link, { to: "/", className: s.footerList, "aria-label": "logo link", children: _jsx(SvgSelector, { id: "logo" }) }), _jsxs("div", { className: s.footerTopLi, style: { marginLeft: '25px' }, children: [_jsxs("div", { style: { marginBottom: '4px' }, children: [t('footer.about.ooo'), ", ", t('footer.about.inn'), ", ", t('footer.about.ogrn')] }), _jsxs("div", { children: [_jsx("i", { className: "fas fa-phone-alt", style: { marginRight: '8px', marginBottom: '4px' } }), t('footer.about.phone')] }), _jsxs("div", { children: [_jsx("i", { className: "fas fa-envelope", style: { marginRight: '8px' } }), t('footer.about.mail')] })] }), _jsx("div", { style: { marginLeft: 'auto' }, children: _jsx(Link, { to: "mailto:technoharmony@yandex.ru", "aria-label": "mail link", target: "_blank", children: _jsx(SvgSelector, { id: "footer-mail" }) }) })] })] }) }));
};
