// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --background-white: 255, 255, 255;
  --background-secondary: 247, 249, 251;
  --background-dark-primary: 35, 35, 40;
  --background-dark-secondary: 45, 45, 50;
  --text-primary: 4, 6, 11;
  --color-main-1: 0, 152, 234;
  --color-main-2: 114, 122, 247;
}`, "",{"version":3,"sources":["webpack://./src/styles/root.scss"],"names":[],"mappings":"AAAA;EACE,iCAAA;EACA,qCAAA;EACA,qCAAA;EACA,uCAAA;EAEA,wBAAA;EAEA,2BAAA;EACA,6BAAA;AADF","sourcesContent":[":root {\n  --background-white: 255, 255, 255;\n  --background-secondary: 247, 249, 251;\n  --background-dark-primary: 35, 35, 40;\n  --background-dark-secondary: 45, 45, 50;\n  \n  --text-primary: 4, 6, 11;\n  \n  --color-main-1: 0, 152, 234;\n  --color-main-2: 114, 122, 247;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
