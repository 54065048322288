import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Home } from './pages/home';
import { Layout } from './layout';
import { ROUTES } from './utils/router';
import './utils/i18n';
import { t } from "i18next";
const getValidLanguage = (lang) => {
    const validLanguages = ['ru', 'en'];
    return validLanguages.includes(lang) ? lang : 'en';
};
export const App = () => {
    const savedLanguage = localStorage.getItem('i18nextLng');
    const [selectedLanguage, setSelectedLanguage] = useState(savedLanguage ? getValidLanguage(savedLanguage) : 'en');
    const [cookieConsent, setCookieConsent] = useState(false);
    useEffect(() => {
        const consent = localStorage.getItem('cookieConsent');
        if (!consent) {
            setCookieConsent(true);
        }
    }, []);
    const handleCookieAccept = () => {
        localStorage.setItem('cookieConsent', 'true');
        setCookieConsent(false);
    };
    const { i18n } = useTranslation();
    useEffect(() => {
        if (!savedLanguage) {
            const lowerCaseTgLanguage = savedLanguage?.toLowerCase();
            const language = lowerCaseTgLanguage && getValidLanguage(lowerCaseTgLanguage);
            setSelectedLanguage(language || 'en');
        }
    }, [savedLanguage]);
    useEffect(() => {
        i18n.changeLanguage(selectedLanguage);
        localStorage.setItem('i18nextLng', selectedLanguage);
    }, [selectedLanguage, i18n]);
    const cookieBannerStyle = {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: '#000',
        color: '#fff',
        padding: '10px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: '14px',
        zIndex: 9999
    };
    const cookieButtonStyle = {
        backgroundColor: '#007BFF',
        color: 'white',
        border: 'none',
        padding: '8px 16px',
        cursor: 'pointer',
        borderRadius: '5px',
        fontSize: '14px'
    };
    return (_jsxs(Layout, { setSelectedLanguage: setSelectedLanguage, selectedLanguage: selectedLanguage, children: [_jsxs(Routes, { children: [_jsx(Route, { path: ROUTES.HOME, element: _jsx(Home, {}) }), _jsx(Route, { path: "*", element: _jsx(Navigate, { to: "/", replace: true }) })] }), cookieConsent && (_jsxs("div", { style: cookieBannerStyle, children: [_jsx("p", { children: t('cookies.text') }), _jsx("button", { onClick: handleCookieAccept, style: cookieButtonStyle, children: "\u041E\u041A" })] }))] }));
};
