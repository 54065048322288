import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-len */
/* eslint-disable import/no-cycle */
/* eslint-disable no-confusing-arrow */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useRef, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container } from '../../components/container';
import { Button } from '../../components/ui/button';
import { contactsRef } from '../contacts';
import { servicesRef } from '../../pages/home/services';
import { providingRef } from '../../pages/home/providing';
import { advantagesRef } from '../../pages/home/advantages';
import { SvgSelector } from '../../assets/svg-selector';
import s from './header.module.scss';
export const Header = ({ setSelectedLanguage, selectedLanguage }) => {
    const headerRef = useRef(null);
    const { pathname, hash } = useLocation();
    const { t } = useTranslation();
    const [activeSection, setActiveSection] = useState('');
    const [menuOpen, setMenuOpen] = useState(false);
    useEffect(() => {
        document.documentElement.style.overflowY = menuOpen ? 'hidden' : 'auto';
    }, [menuOpen, setMenuOpen]);
    const handleToggleMenu = () => {
        setMenuOpen(!menuOpen);
    };
    const scroll = (ref, flag) => {
        if (ref.current) {
            const headerHeight = flag ? 180 : 0;
            const targetOffsetTop = ref.current.getBoundingClientRect().top + window.scrollY;
            window.scrollTo({
                top: targetOffsetTop - headerHeight,
                behavior: 'smooth'
            });
            setMenuOpen(false);
        }
    };
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                headerRef.current?.classList.add(s.activeHeader);
            }
            else {
                headerRef.current?.classList.remove(s.activeHeader);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    useEffect(() => {
        window.scrollTo(0, 0);
        if (hash) {
            const targetElement = document.querySelector(hash);
            if (targetElement) {
                const headerHeight = hash === '#providing' ? 180 : 0;
                targetElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'nearest'
                });
                window.scrollBy(0, -headerHeight);
                setMenuOpen(false);
            }
        }
        window.history.replaceState(null, '', pathname);
    }, [pathname, hash]);
    useEffect(() => {
        if (pathname !== '/') {
            setActiveSection('');
        }
        const handleScroll = () => {
            const offset = 20;
            if (window.scrollY >= (providingRef.current.offsetTop - offset)
                && window.scrollY < (advantagesRef.current.offsetTop - offset)) {
                setActiveSection('providing');
            }
            else if (window.scrollY >= (advantagesRef.current.offsetTop - offset)
                && window.scrollY < (servicesRef.current.offsetTop - offset)) {
                setActiveSection('advantages');
            }
            else if (window.scrollY >= (servicesRef.current.offsetTop - offset)
                && window.scrollY < (contactsRef.current.offsetTop - offset)) {
                setActiveSection('services');
            }
            else if (window.scrollY >= (contactsRef.current.offsetTop - offset)) {
                setActiveSection('contacts');
            }
            else {
                setActiveSection('');
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [providingRef, advantagesRef, servicesRef, contactsRef, pathname]);
    return (_jsx("header", { ref: headerRef, className: s.header, style: { background: pathname !== '/' ? '#fff' : '' }, children: _jsx(Container, { children: _jsxs("div", { className: s.headerRow, children: [_jsxs("div", { className: s.headerRowLeft, children: [_jsx(Link, { to: "/", "aria-label": "logo link", className: s.headerLogo, children: _jsx(SvgSelector, { id: "logo" }) }), _jsx("nav", { className: s.headerNav, children: _jsxs("ul", { className: `${s.burgerMenuList} ${s.headerList} ${menuOpen ? s.burgerMenuListOpen : ''}`, children: [_jsx("li", { className: `${activeSection === 'providing' ? s.active : ''}`, children: _jsx(Link, { className: `${s.headerLink}`, to: "/#providing", "aria-label": "providing link", onClick: (event) => {
                                                    if (pathname === '/') {
                                                        event.preventDefault();
                                                    }
                                                    setActiveSection('providing');
                                                    scroll(providingRef, true);
                                                }, children: t('header.about-us') }) }), _jsx("li", { className: `${activeSection === 'advantages' ? s.active : ''}`, children: _jsx(Link, { className: `${s.headerLink}`, to: "/#advantages", "aria-label": "advantages link", onClick: (event) => {
                                                    if (pathname === '/') {
                                                        event.preventDefault();
                                                    }
                                                    setActiveSection('advantages');
                                                    scroll(advantagesRef);
                                                }, children: t('header.team') }) }), _jsx("li", { className: `${activeSection === 'services' ? s.active : ''}`, children: _jsx(Link, { className: `${s.headerLink}`, to: "/#services", "aria-label": "services link", onClick: (event) => {
                                                    if (pathname === '/') {
                                                        event.preventDefault();
                                                    }
                                                    setActiveSection('services');
                                                    scroll(servicesRef);
                                                }, children: t('header.services') }) }), _jsx("li", { className: `${activeSection === 'contacts' ? s.active : ''}`, children: _jsx("div", { className: `${s.headerLink}`, onClick: (event) => {
                                                    if (pathname === '/') {
                                                        event.preventDefault();
                                                    }
                                                    setActiveSection('contacts');
                                                    scroll(contactsRef);
                                                }, children: t('header.contacts') }) })] }) })] }), _jsxs("div", { className: s.headerActions, children: [_jsxs(Button, { variant: "primary", className: s.headerActionsLang, onClick: () => {
                                    setSelectedLanguage(selectedLanguage => selectedLanguage === 'ru' ? 'en' : 'ru');
                                }, children: [_jsx(SvgSelector, { id: "lang" }), " ", selectedLanguage === 'ru' ? 'Рус' : 'Eng'] }), _jsxs(Button, { variant: "action", className: s.headerActionsContact, onClick: () => scroll(contactsRef), children: [_jsx(SvgSelector, { id: "message" }), " ", t('common.contact-us')] }), _jsxs("button", { className: `${s.burgerMenu} ${menuOpen && s.burgerMenuOpen}`, onClick: handleToggleMenu, "aria-label": "Burger Menu Button", children: [_jsx("span", {}), _jsx("span", {}), _jsx("span", {})] })] })] }) }) }));
};
